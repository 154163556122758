<template>
  <ComingSoon />
</template>

<script>
import ComingSoon from "./ComingSoon.vue";
export default {
  components: {
    ComingSoon: ComingSoon,
  },
};
</script>

<style lang="scss" scoped>
iframe {
  height: 70vh;
  width: 100%;
  padding-top: 15vh;
  padding-bottom: 15vh;
}
</style>
