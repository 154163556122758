<template>
  <section>
    <div class="title">
      <div class="typewriter">
        <span class><h1>Coming Soon...</h1></span>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  height: 100vh;
  background: url("../assets/images/backgrounds/comingsoonbackground.jpg");
  background-position: center;
  background-size: cover;
}
h1 {
  color: white;
  overflow: hidden;
  white-space: nowrap;
}
.typewriter {
  animation: typing 5s steps(30, end), blink-caret 0.5s step-end infinite;
  border-right: 0.15em solid white;
  margin: auto;
  width: 210px;
}
.title {
  padding-top: 45vh;
  width: 210px;
  margin: auto;
  text-align: center;
  letter-spacing: 0.15em; /* Adjust as needed */
}
iframe {
  height: 70vh;
  width: 100%;
  padding-top: 15vh;
  padding-bottom: 15vh;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}
</style>
